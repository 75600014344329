<template>
  <div class="flex justify-evenly bg-darkBrown pb-3 absolute bottom-0 w-full">
    <a href="/mobile/menu" class="display-block">
      <BaseIcon name="homeWhite" outline= false class="mx-auto"/>
      <p class="text-white font-medium font-body text-bodyS">{{ $t('menu.menu') }}</p>
    </a>
    <a href="/mobile/qrCode" class="display-block bg-redDamask border-t border-r border-l border-halfColonialWhite px-2 rounded-t-full -mb-3 -mt-2 pt-2">
      <BaseIcon name="qrCodeWhite" outline= false class="mx-auto"/>
      <p class="text-white font-medium font-body text-bodyS">{{ $t('menu.qr') }}</p>
    </a>
    <a href="/timelineedit" class="display-block" >
      <BaseIcon name="timelineWhite" outline= false class="mx-auto"/>
      <p class="text-white font-medium font-body text-bodyS">{{ $t('menu.timeline') }}</p>
    </a>
  </div>
</template>

<script>

import BaseIcon from '@/components/BaseIcon.vue';

export default {
  name: 'MobileNavigation',
  components: {
    BaseIcon
  },
  mounted() {
    this.$i18n.locale = this.lang;
  },
  data() {
    return {
      lang: localStorage.getItem('language') ? localStorage.getItem('language') : 'ro',
    }
  }
}
</script>
