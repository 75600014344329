<template>
    <div class="max-w-screen-mobile mx-auto h-screen py-2 relative">
        <section>
            <MobileHeader />
            <input type='text' id='quicksearchInput' placeholder="Keresés" v-model="quicksearchInput" ref="input" v-on:change="searchChange">
            <ul>
                <li v-for="post of posts" :key="post.id">
                    <EntityList :content = post />
                </li> 
            </ul>
            <pagination :pagination="pagination"
                @prev="--postsData.page; getPosts();"
                @next="postsData.page++; getPosts();">
            </pagination>
            <MobileNavigation />
        </section>
    </div>
</template>

<script>

import MobileHeader from "@/components/mobile/Header.vue";
import pagination from "@/components/Pagination.vue";
import EntityList from "@/components/mobile/EntityList.vue";
import MobileNavigation from "@/components/mobile/Navigation.vue";

export default {
    name: "Tablet",
    components: {
        MobileHeader,
        'pagination': pagination,
        EntityList,
        MobileNavigation
    },
    mounted() {
        this.getPosts();
    },
    data(){
        return {
            posts: [],
            baseURL: process.env.VUE_APP_AXIOS_URL,
            postsData: {
                per_page: 3,
                page: 1,
                lang: localStorage.getItem('language') ? localStorage.getItem('language') : 'ro',
                search: '',
            },
            pagination: {
                prevPage: '',
                nextPage: '',
                totalPages: '',
                from: '',
                to: '',
                total: '',
            },
        }
    },
    methods: {
        getPosts() {
            this.axios.get(this.baseURL + `/wp-json/wp/v2/mobiles`, {params: this.postsData}).then(response => {
                this.posts = response.data;
                this.configPagination(response.headers);
            }).catch( (error) => {
                console.log(error);
            });
        },
        configPagination(headers) {
            this.pagination.total = +headers['x-wp-total'];
            this.pagination.totalPages = +headers['x-wp-totalpages'];
            this.pagination.from = this.pagination.total ? ((this.postsData.page - 1) * this.postsData.per_page) + 1 : ' ';
            this.pagination.to = (this.postsData.page * this.postsData.per_page) > this.pagination.total ? this.pagination.total : this.postsData.page * this.postsData.per_page;
            this.pagination.prevPage = this.postsData.page > 1 ? this.postsData.page : '';
            this.pagination.nextPage = this.postsData.page < this.pagination.totalPages ? this.postsData.page + 1 : '';
        },
        searchChange() {
            
            const search_input = document.querySelector('#quicksearchInput').value;
            if (search_input.length > 2 ) {
                this.postsData.search = search_input;
            } else {
                this.postsData.search =  '';
            }
            this.getPosts();
        },
        langchanged_(value) {
            this.postsData.page = 1;
            this.postsData.lang = value;
            this.getPosts();
        }
    },
    watch: {
        quicksearchInput: function(value) {
            console.log(value);
            this.onChange();
        },
    },
};
</script>
