<template>
  <div class="h-24 p-3 mx-2.5 hover:bg-halfColonialWhite active:bg-halfColonialWhite hover:bg-opacity-10 active:bg-opacity-10 rounded cursor-pointer inline-block flex flex-none"  :id="postArray.entity_id">
      <div class="w-14 h-16 rounded-t-full rounded-b">
        <img :src="postArray.src" :alt="postArray.alt" class="mx-auto h-full overflow-hidden rounded-t-full rounded-b">
      </div>
      <div class="pl-3.5 h-full flex-grow max-w-xs">
        <h6 class="font-heading text-h6 text-sepiaBlack dark:text-white truncate">{{ postArray.title }}</h6>
        <p class="font-body text-bodyS text-sepiaBlack dark:text-halfColonialWhite dark:text-opacity-40">{{ postArray.era_date }}</p>
      </div>
      <BaseIcon name="arrowRight" outline= false />
  </div>
</template>

<script>

import BaseIcon from '@/components/BaseIcon.vue';

export default {
  name: 'EntityListMobile',
  components: {
    BaseIcon
  },
  props: {
    content: {
      type: Object,
      require: true,
      default: () => {}
    }
  },
  computed: {
    postArray() {
      return {
        title: this.content?.title,
        text: this.content?.sport_content,
        src: this.content?.img,
        alt: this.content?.alt,
        link: this.content?.link,
        entity_id: this.content?.id,
        era_date: this.content?.era_date
      }
    }
  }
}
</script>
